import * as React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import Seo from '../components/seo';

const IndexPage = function () {
  return (
    <Layout>
      <Seo
        title="Showjumpers"
        description="Southern Cross – where care and class go hand in hand. Luxury facility, country prices…"
      />
      <main className="lg:relative">
        <div className="mx-auto max-w-7xl w-full pt-16 pb-20  lg:py-48 lg:text-left">
          <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16 ">
            <h1 className="text-4xl w-11/12 lg:w-full mx-auto tracking-tight font-serif  text-gray-900 sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl  text-center lg:text-left">
              <span className="inline">Southern Ontario’s only</span>{' '}
              <span className="text-brand-blue-200 inline">
                &#8220;Luxury Included&#8221;
              </span>{' '}
              <span className="inline" />
              <span className="inline">Equestrian Facilities</span>
            </h1>
            <div className="mt-5 prose prose-lg prose-indigo text-gray-500 mx-auto  md:max-w-xl lg:row-start-1 lg:col-start-1">
              <p>Southern Cross – where care and class go hand in hand.</p>

              <p>
                Located 10 minutes off the 403 near Brantford. Approximately an
                hour from Toronto, Central to London, Burlington, Kitchener, and
                Simcoe.
              </p>

              <p>Luxury facility, country prices…</p>
            </div>
          </div>
        </div>
        <div className="relative w-full  sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <StaticImage
            src="../images/southern-cross-front.png"
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Front View of Southern Cross"
            className="inset-0 w-full h-full object-right"
          />
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;
